import React from "react";

interface IHeroProps {
  belowLine: React.ReactNode;
  aboveLine: React.ReactNode;
}

const HeroCard: React.FC<IHeroProps> = ({ belowLine, aboveLine }) => (
  <div className="lg:w-[159px] w-full h-auto lg:h-[158px] lg:rounded-3xl rounded-2xl border-2 border-white border-opacity-20 bg-white bg-opacity-10 p-4 shadow-3xl flex lg:block justify-between items-center">
    {belowLine}
    <div className="bg-white bg-opacity-20 h-[1px] w-full my-4 hidden lg:block" />
    {aboveLine}
  </div>
);

export default HeroCard;
