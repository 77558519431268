import React, { useContext } from "react";

import { ReactComponent as ClockIcon } from "../../images/icons/clock.svg";
import { ReactComponent as LockIcon } from "../../images/icons/lock.svg";
import { ArrowRightGradientIcon } from "../../images/icons";
import Button from "../UI/Button";
import classNames from "classnames";
import useWindowSize from "../../hooks/useWindowSize";
import { Link } from "gatsby";
import useBoolean from "../../hooks/useBoolean";

interface ILessonCardProps {
  backgroundColor: string[];
  lessonNumber: number;
  title: string;
  description: string;
  isBlocked?: boolean;
  slug: string;
  categorySlug: string;
  timeToLearn: number;
}

const LessonCard: React.FC<ILessonCardProps> = ({
  backgroundColor,
  lessonNumber,
  title,
  description,
  isBlocked,
  slug,
  categorySlug,
  timeToLearn,
}) => {
  const { width } = useWindowSize();
  const [isHover, setHover] = useBoolean();
  const isMoreThanMd = width >= 768;
  const path = `/learn/${categorySlug}/lessons/${slug}`;

  const renderButton = () => (
    <div className="w-[68px]">
      <Button
        to={path}
        EndIcon={isBlocked ? LockIcon : ArrowRightGradientIcon}
        type="outline"
        color="custom-gradient"
        className="!h-[44px] !w-[68px] text-white"
        size="small"
        onHover={setHover.on}
        onLeave={setHover.off}
        gradientFill={isBlocked ? "#f5f6fa" : "#ffffff"}
        gradientStart={
          !isBlocked ? backgroundColor?.[0] ?? "#FFFFFF" : "#DE4F41"
        }
        gradientEnd={!isBlocked ? backgroundColor?.[1] ?? "#FFFFFF" : "#E01890"}
        iconProps={{
          colorStart: !isBlocked
            ? backgroundColor?.[0] ?? "#FFFFFF"
            : "#DE4F41",
          colorEnd: !isBlocked ? backgroundColor?.[1] ?? "#FFFFFF" : "#E01890",
          className:
            isHover && isBlocked
              ? "!w-5 !h-5 reset-icon"
              : isHover
              ? "reset-icon"
              : isBlocked
              ? "!w-5 !h-5 red-icon"
              : undefined,
        }}
      />
    </div>
  );

  return (
    <div
      className={classNames(
        "w-full flex flex-col md:flex-row gap-3 md:gap-6 p-4 md:p-6 justify-between border bg-white border-grey-100 rounded-2xl",
        {
          "!bg-grey-300 !border-0": isBlocked,
          "border-grey-200": isHover
        }
      )}
    >
      <div className="w-10">
        <span
          className="w-10 h-[38px] rounded-lg flex items-center justify-center text-white bg-grey-400"
          style={{
            background: isBlocked
              ? undefined
              : `linear-gradient(to right, ${
                  backgroundColor?.[0] ?? "#FFFFFF"
                } 0%, ${backgroundColor?.[1] ?? "#FFFFFF"} 100%)`,
          }}
        >
          {lessonNumber < 10 && "0"}
          {lessonNumber}
        </span>
      </div>
      <div className="flex flex-col w-full">
        <Link to={path}>
          <h4 className="text-xl font-semibold text-black-light">{title}</h4>
        </Link>
        <p className="pt-4 md:pt-6 pb-3 md:pb-[17px] text-grey-lite text-sm">
          {description}
        </p>
        <div className="w-full flex justify-between items-center">
          <div className="flex items-center gap-3">
            <ClockIcon className="text-black-light w-6 h-6" />
            <span className="text-black-light text-sm font-semibold">
              {(timeToLearn / 60).toFixed(0)}h {(timeToLearn % 60).toFixed(0)}m
            </span>
          </div>
          {!isMoreThanMd && renderButton()}
        </div>
      </div>
      {isMoreThanMd && renderButton()}
    </div>
  );
};

export default LessonCard;
