import React, { useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { ILesson } from "../../templates/category";
import FeaturesBanner from "../UI/FeaturesBanner";
import AboutCourse from "./AboutCourse";
import Author from "./Author";
import LessonCard from "./LessonCard";

interface ICourseContainerProps {
  subtitle: string;
  aboutCourse: string[];
  lessons: ILesson[];
  backgroundColor: string[];
  categorySlug: string;
}

const CourseContainer: React.FC<ICourseContainerProps> = ({
  subtitle,
  lessons,
  categorySlug,
  backgroundColor,
  aboutCourse,
}) => {
  const { isAuthenticated, subscription } = useContext(AuthContext);

  return (
    <div className="flex max-w-[1188px] justify-center gap-10 lg:gap-16 px-6 lg:flex-row flex-col mx-auto lg:pt-16 pb-14 lg:pb-[116px]">
      <div className="w-full pt-10 lg:p-0">
        <div className="pt-10 md:pt-6 space-y-4 md:space-y-3">
          <span className="text-sm text-grey-400">Lesson overview:</span>
          {lessons.map((lesson, idx) => (
            <LessonCard
              lessonNumber={idx + 1}
              title={lesson.title}
              key={lesson.order}
              description={lesson.custom_excerpt}
              backgroundColor={backgroundColor}
              categorySlug={categorySlug}
              slug={lesson.slug}
              timeToLearn={lesson.time_to_learn ?? 0}
              isBlocked={
                (lesson.visibility === "members" && !isAuthenticated) ||
                (lesson.visibility === "premium" && !subscription)
              }
            />
          ))}
        </div>
      </div>
      <Author />
    </div>
  );
};

export default CourseContainer;
