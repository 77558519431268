import { StaticImage } from "gatsby-plugin-image";
import React from "react";

import { ReactComponent as FacebookIcon } from "../../images/icons/facebook.svg";
import { ReactComponent as TwitterIcon } from "../../images/icons/twitter.svg";
import { ReactComponent as LinkedinIcon } from "../../images/icons/linkedin.svg";

const Author: React.FC = () => {
  return (
    <div className="md:max-w-[270px] w-full mx-auto">
      <div className="flex justify-center">
        <StaticImage
          className="w-[140px] h-[140px] rounded-full overflow-hidden"
          imgClassName="w-[140px] h-[140px] rounded-full"
          src="../../images/me.jpg"
          alt="Benjamin Wootton"
        />
      </div>
      <span className="text-sm text-grey-400 text-center block pt-6">
        Your Course Curator
      </span>
      <h4 className="text-white text-center text-xl font-semibold mt-4">
        Benjamin Wootton
      </h4>
      <span className="text-sm text-grey-400 text-center block pt-2">
        Ensemble Founder
      </span>
      <div className="flex gap-2 py-6 justify-center">
        <a
          href="#"
          target="_blank"
          rel="noreferrer noopener"
          className="w-12 h-12 border-2 border-white rounded-full flex items-center justify-center"
        >
          <FacebookIcon className="text-white" />
        </a>
        <a
          href="https://twitter.com/benjaminwootton"
          target="_blank"
          rel="noreferrer noopener"
          className="w-12 h-12 border-2 border-white rounded-full flex items-center justify-center"
        >
          <TwitterIcon className="text-white" />
        </a>
        <a
          href="#"
          target="_blank"
          rel="noreferrer noopener"
          className="w-12 h-12 border-2 border-white rounded-full flex items-center justify-center"
        >
          <LinkedinIcon className="text-white" />
        </a>
      </div>
      <p className="text-grey-400 italic text-center text-sm">
      </p>
    </div>
  );
};

export default Author;
