import React from "react";

import HeroCard from "./HeroCard";
import { LevelIcon } from "../../images/icons";
import { ReactComponent as BookIcon } from "../../images/icons/book.svg";
import { ReactComponent as ClockIcon } from "../../images/icons/clock.svg";

interface IHeroProps {
  title: string;
  subtitle: string;
  description: string;
  background_color: string[];
  totalLessons: number;
  level: 1 | 2 | 3;
  totalTime: number;
}

const Hero: React.FC<IHeroProps> = ({
  title,
  subtitle,
  description,
  background_color,
  totalLessons,
  level,
  totalTime,
}) => {
  return (
    <div
      className="lg:pt-[134px] lg:pb-[68px] pb-10 pt-[96px] rounded-bl-[45px] rounded-br-[45px] xl:rounded-br-[100px] xl:rounded-bl-[100px] px-6"
      style={{
        background: `linear-gradient(to right, ${background_color[0]} 0%, ${background_color[1]} 100%)`,
      }}
    >
      <div className="flex justify-between lg:flex-row flex-col gap-8 lg:gap-[78px] max-w-[1140px] mx-auto w-full">
        <div className="lg:max-w-[550px] space-y-4">
          <h1 className="lg:text-[44px] text-[32px] font-bold text-white">
            {title}
          </h1>
          <p className="text-white">{description}</p>
        </div>
        <div className="xl:max-w-[550px] w-full flex-wrap flex gap-2 lg:gap-4">
          <HeroCard
            belowLine={
              <div className="lg:w-full gap-[17.56px] lg:gap-[14.56px] flex items-center lg:flex-col flex-row">
                <BookIcon className="text-white w-[28.67px] h-[26.88px]" />
                <span className="text-sm text-white">Program:</span>
              </div>
            }
            aboveLine={
              <span className="block text-center text-md font-semibold text-white">
                {totalLessons} Lessons
              </span>
            }
          />
          <HeroCard
            belowLine={
              <div className="lg:w-full gap-[17.56px] lg:gap-[14.56px] flex items-center lg:flex-col flex-row">
                <LevelIcon
                  number={level}
                  className="text-white w-[28.67px] h-[26.88px]"
                />
                <span className="text-sm text-white">Level:</span>
              </div>
            }
            aboveLine={
              <span className="block text-center text-md font-semibold text-white">
                {level === 1 && "Beginner"}
                {level === 2 && "Intermediate"}
                {level === 3 && "Advanced"}
              </span>
            }
          />
          <HeroCard
            belowLine={
              <div className="lg:w-full gap-[17.56px] lg:gap-[14.56px] flex items-center lg:flex-col flex-row">
                <ClockIcon className="text-white w-[28.67px] h-[26.88px]" />
                <span className="text-sm text-white">Total Time:</span>
              </div>
            }
            aboveLine={
              <span className="block text-center text-md font-semibold text-white">
                {(totalTime / 60).toFixed(0)}h {(totalTime % 60).toFixed(0)}m
              </span>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;
