import React, { useMemo } from "react";
import DefaultLayout from "../components/layouts/DefaultLayout";
import { graphql } from "gatsby";
import lessonMapper from "../helpers/lessonMapper";
import Hero from "../components/CoursePage/Hero";
import SEO from "../components/layouts/SEO";
import CourseContainer from "../components/CoursePage/CourseContainer";

export interface ILesson {
  category: string[];
  cover_image: string;
  custom_excerpt: string;
  excerpt: string;
  order: number;
  slug: string;
  title: string;
  visibility: string | null;
  type: string;
  time_to_learn?: number;
  lesson_contents?: string[];
  embeddedImagesLocal?: unknown;
  next_lesson_description?: string;
}

const CategoryPage: React.FC<{ data: any }> = ({ data }) => {
  const category = data?.category?.frontmatter;
  const lessons = lessonMapper(data.course_contents) as { data: ILesson[] };
  const totalTime = lessons.data.reduce(
    (sum, lesson) => (sum += lesson.time_to_learn ?? 0),
    0
  );
  const siteUrl = data.site.siteMetadata.siteUrl;
  const isColorful = category?.background_color?.[0] === "#560e4a" && category?.background_color?.[1] === "#170737";

  return (
    <>
      <SEO
        title={`${category?.title} | Ensemble`}
        desc={category?.description}
        url={`${siteUrl}/learn/categories/${category?.slug}`}
        ogImage={`${siteUrl}/assets/img/ensemble_background.png`}
      />
      <DefaultLayout isColorfulLogo={isColorful}>
        {category && (
          <Hero
            title={category?.title}
            subtitle={category?.subtitle}
            description={category?.description}
            background_color={category?.background_color}
            totalLessons={lessons.data.length}
            level={category?.level}
            totalTime={totalTime}
          />
        )}
        <CourseContainer
          subtitle="Course Overview"
          lessons={lessons.data}
          backgroundColor={category?.background_color}
          categorySlug={category?.slug}
          aboutCourse={category?.about_course ?? []}
        />
      </DefaultLayout>
    </>
  );
};

export const pageQuery = graphql`
  query ($category_slug: String) {
    site {
      siteMetadata {
        title
        description
        siteUrl
      }
    }

    category: mdx(
      slug: { eq: $category_slug }
      fields: { source: { eq: "categories" } }
    ) {
      frontmatter {
        title
        subtitle
        description
        background_color
        background_image
        heading_title
        level
        about_course
        slug
      }
    }

    course_contents: allMdx(
      filter: {
        fields: { source: { in: ["content"] } }
        frontmatter: { category: { eq: $category_slug } }
      }
      limit: 5000
      sort: { fields: frontmatter___order, order: ASC }
    ) {
      totalCount
      nodes {
        frontmatter {
          title
          tocTitle
          slug
          type
          cover_image
          order
          category
          custom_excerpt
          time_to_learn
          section
          visibility
        }
        excerpt
      }
    }
  }
`;

export default CategoryPage;
