const lessonMapper = (query) => {
    return {
        total: query.totalCount,
        data: query.nodes.reduce((arr, l) => {
            if (arr.some(el => el.slug === l.frontmatter.slug)) {
                return arr;
            }

            const formatted = { ...l, ...l.frontmatter };
            delete formatted.frontmatter;
            return [...arr, formatted];
        }, []),
    };
};

export default lessonMapper;
